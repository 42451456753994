import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PrivacyPolicyContentTemplate from "../templates/legal/privacy-policy-content-template"
import {Helmet} from 'react-helmet'


const PrivacyPolicy = () => (
  <>
    <Layout>
      <Helmet>
        <body className="inner-page no-hero legal-page" data-active-page="page-privacy-policy" />
      </Helmet>
      <SEO title="Privacy Policy" />
      <section className="content-container">
        <PrivacyPolicyContentTemplate />
      </section>
    </Layout>
  </>
)

export default PrivacyPolicy
